@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.react-international-phone-input-container .react-international-phone-input {
  width: 100%;
  border: none !important;
  height: 99.8% !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #6672d7;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Button Styles*/
.btn-container {
  cursor: pointer;
  border: 0.1vw solid;
  border-radius: 1vw;
  height: 3vw;
  display: flex;
  align-items: center;
}
.btn-container2 {
  cursor: pointer;
  border: 0.1vw solid;
  border-radius: 1vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-container2:hover {
  cursor: pointer;
  background-color: #73dc6f;
  transition: 0.3s;
}
.btn-container2:hover .textBtn-container2 {
  font-weight: 700;
  transition: 0.3s;
}
.btn-container:hover .icon-container {
  background-color: #73dc6f;
  border-top-left-radius: 1vw;
  transition: 0.3s;
}
.btn-container .icon-container {
  width: 20%;
  border-top-left-radius: 1vw;
  border-bottom-left-radius: 1vw;
  border-right: 0.1vw solid;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.icon-container svg {
  width: 18px;
}
.textBtn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1vw;
  width: 80%;
  font-family: "Guaruja Neue Regular";
}
.textBtn-container2 {
  display: flex;
  transition: 0.3s;
  margin-left: 1vw;
  font-size: 1.1vw;
  align-items: center;
  justify-content: center;
  font-family: "Guaruja Neue Regular";
}
/*Input Styles*/
.input-container {
  border: 0.1vw solid;
  border-radius: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}
.input-container2 {
  border: 0.1vw solid;
  border-radius: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}
.input-container .icon-container {
  background-color: #73dc6f;
  width: 20%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 0.1vw solid;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-container .input-item {
  font-family: "Guaruja Neue Regular";
  font-size: 1rem;
  height: 95%;
  width: 100%;
  padding: 0vw 1vw;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.input-container2 .input-item {
  font-family: "Guaruja Neue Regular";
  font-size: 1rem;
  height: 95%;
  width: 100%;
  padding: 0vw 1vw;
  border: none;
  border-radius: 10px;
  color: black;
}
p.matchPassText {
  margin: 0;
  font-size: 1vw;
  font-family: "Guaruja Neue Regular";
  text-align: center;
}
.input-label {
  font-family: "Guaruja Neue Regular";
  padding: 0.2vw 0.5vw;
  display: flex;
  align-items: flex-end;
}
.input-specs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.inputSpec {
  display: flex;
  align-items: center;
  font-family: "Guaruja Neue Regular";
  font-size: 1rem;
  margin-bottom: 0.5vw;
}
.inputSpec > .active {
  fill: #4944fc;
}
.inputSpec > .error {
  fill: red;
}
.inputSpec svg {
  width: 18px;
  height: 18px;
}
.inputSpec span {
  margin-left: 0.3vw;
}
.inputsCode-container {
  display: flex;
  margin-top: 1vw;
  margin-bottom: 1vw;
  justify-content: space-evenly;
  align-items: center;
}
input.code-input {
  width: 4vw;
  height: 4vw;
  font-family: "Guaruja Neue Regular";
  font-weight: 600;
  font-size: 2.5vw;
  border-radius: 1vw;
  border: 1px solid grey;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.profile-image-initials {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 1.8vw;
}

.react-tooltip {
  text-align: justify;
}
/*ProgressBar Styles*/
.progressBar-container {
  position: relative;
  border: 1px solid;
  padding: 1vw;
  background-color: white;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.line-progressBar {
  width: 79%;
  background-color: rgb(197, 197, 197);
  height: 0.2vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.line-progressBar-inner {
  position: absolute;
  height: 0.2vw;
  left: 6%;
  width: 0;
  transition: 1s;
}
.porcent-progressBar {
  font-size: 1.2vw;
  font-family: "Guaruja Neue Regular";
  font-weight: 700;
}
.dot-progressBar {
  width: 2vw;
  height: 2vw;
  transition: 0.8s;
  border-radius: 2vw;
  background-color: rgb(197, 197, 197);
}
/*Onboarding Styles*/
.general-container {
  background-color: #f6f4ea;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  z-index: -10;
}
.patternBackground {
  width: 100vw;
  height: 106%;
}
.footer-container {
  /* background-color: #1a222f; */
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.selloAnimado {
  -webkit-animation: 2s rotate linear infinite;
  animation: 2s rotate linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  padding: 2vw;
}
.footer-container .selloAnimado {
  -webkit-animation: 5s rotate linear infinite;
  animation: 5s rotate linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  padding: 2vw;
}
.welcome-container {
  animation-name: slidein;
  animation-duration: 1s;
  box-shadow: 0px 1px 24px -3px rgba(145, 145, 145, 0.75);
  -webkit-box-shadow: 0px 1px 24px -3px rgba(145, 145, 145, 0.75);
  -moz-box-shadow: 0px 1px 24px -3px rgba(145, 145, 145, 0.75);
  position: absolute;
  left: 500px;
  background-color: #f6f4ea;
  border-radius: 0.8vw;
  border: #1a222f solid 0.1vw;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 40vw;
  transition: 1s;
  height: fit-content;
}
.preliminaryCard-container {
  animation-name: slidein;
  margin-top: 2vw;
  animation-duration: 1s;
  box-shadow: 0px 1px 24px -3px rgba(145, 145, 145, 0.75);
  -webkit-box-shadow: 0px 1px 24px -3px rgba(145, 145, 145, 0.75);
  -moz-box-shadow: 0px 1px 24px -3px rgba(145, 145, 145, 0.75);
  z-index: 4000;
  background-color: #f6f4ea;
  border-radius: 0.8vw;
  border: #1a222f solid 0.1vw;
  display: flex;
  flex-direction: column;
  width: 40vw;
  transition: 1s;
  height: fit-content;
}
.aceptarCotizacion-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.preliminaryCard-container.realQ {
  animation-name: none;
  margin-top: 0;
  animation-duration: 1s;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  z-index: 4000;
  background-color: #f6f4ea;
  border-radius: 0.8vw;
  border: #1a222f solid 0.1vw;
  display: flex;
  flex-direction: column;
  width: 90%;
  transition: 1s;
  height: fit-content;
  color: black;
}
.info-container.realQ {
  display: flex;
  flex-direction: column;
}
.quotationBig-container {
  display: flex;
  padding: 0vw 1vw;
  margin-bottom: 1vw;
  justify-content: space-between;
}
.amount-info-container.left-container {
  width: 48%;
  margin-top: 1vw;
}
.amount-info-container.right-container {
  width: 48%;
  margin-top: 1vw;
}
.preliminaryCard-container .header-container {
  background-color: #4944fc;
  border-top-left-radius: 0.8vw;
  border-top-right-radius: 0.8vw;
  font-family: "Guaruja Neue Regular";
  color: white;
  padding: 0.3vw;
  text-align: center;
  font-size: 1.8vw;
}
.preliminaryCard-container .info-container {
  padding: 1vw;
  width: 100%;
}
@keyframes slidein {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}
.info-container {
  padding: 2.5vw;
  padding-top: 1.5vw;
  width: 90%;
  transition: 1s;
}
.btnAction-container {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-left: #1a222f solid 0.1vw;
  background-color: #73dc6f;
  width: 10%;
  transition: 1s;
  display: flex;
}
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
img.iconView {
  width: 16vw;
}
svg.backButton {
  width: 2vw;
  height: 2vw;
}
/* .header-container img.iconView {
  margin-top: -5vw;
} */
.back-container {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  display: flex;
  border-radius: 50%;
  transition: 0.3s;
}
.back-container:hover {
  background-color: rgba(226, 226, 226, 0.377);
}
.back-container:hover svg {
  stroke: #4944fc;
  transition: 0.3s;
}
.title-container {
  font-size: 4vw;
  font-weight: 700;
  color: #1a222f;
  letter-spacing: -2px;
}
.title-container span {
  font-size: 4vw;
  font-weight: 700;
  color: #1a222f;
  letter-spacing: -2px;
}
.title-container-register span {
  font-size: 3.5vw;
  font-weight: 700;
  color: #1a222f;
  letter-spacing: -2px;
  line-height: 3.5vw;
}
.text-container {
  font-family: "Guaruja Neue Regular";
  margin-top: 1vw;
}
.btnAction-container .btn {
  cursor: pointer;
  /* z-index: 4100; */
  margin: auto;
}
img.btnNext {
  width: 5vw;
  margin-left: -1.5vw;
}
.welcome-btn-container {
  margin-top: 2vw;
  width: 18vw;
}
.leasing-container {
  margin-top: 1vw;
}
.leasingText-btn {
  cursor: pointer;
  margin-bottom: 2vw;
  font-family: "Guaruja Neue Regular";
  font-size: 1.2vw;
  text-decoration: underline;
}
.form-container {
  margin-top: 2vw;
  width: 100%;
}
.form-container-register {
  margin-top: 1vw;
  width: 100%;
}
.form-container-register .input-container {
  margin-bottom: 0.5vw !important;
}
.forgotPass-container {
  text-align: flex-end;
  margin-bottom: 1vw;
}
.forgotPass-text {
  cursor: pointer;
  font-family: "Guaruja Neue Regular";
  font-size: 1vw;
  color: #4944fc;
  transition: 0.3s;
}
.forgotPass-text:hover {
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s;
  color: #1a222f;
}
.divisor-container {
  display: flex;
  font-family: "Guaruja Neue Regular";
  font-weight: 700;
  font-size: 1.2vw;
  align-items: center;
  justify-content: space-between;
}
.line {
  width: 15vw;
  height: 0.1vw;
  border-radius: 0.5vw;
  background-color: black;
}
.otherOptions-container {
  margin-top: 1vw;
}
.leasingView-container {
  /* background-color: #4944fc; */
  animation-name: slideinUp;
  animation-duration: 1s;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  z-index: -10;
  transition: 0.3s;
}
@keyframes slideinUp {
  0% {
    transform: translateY(900px);
  }

  100% {
    transform: translateY(0);
  }
}
.leasingGeneralInfo-container {
  display: flex;
  width: 96vw;
  height: 100vh;
  position: fixed;
  animation-name: slideinUp;
  animation-duration: 1s;
  flex-direction: column;
  align-items: end;
  padding: 2vw;
  transition: 0.3s;
}
.leasing1-container {
  background-color: white;
  border-radius: 0.8vw;
  border: #1a222f solid 0.1vw;
  display: flex;
  margin: auto;
  width: 30vw;
  height: 38vw;
}
.leasingGeneralInfo-container .back-container {
  margin-bottom: 1vw;
}
.leasingCards-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.lateral1-container {
  border-bottom-right-radius: 0.8vw;
  border-top-right-radius: 0.8vw;
  border-left: #1a222f solid 0.1vw;
  background-color: #1a222f;
  width: 10%;
}
.lateral2-container {
  border-bottom-right-radius: 0.8vw;
  border-top-right-radius: 0.8vw;
  border-left: #1a222f solid 0.1vw;
  background-color: #4944fc;
  width: 10%;
}
.lateral3-container {
  border-bottom-right-radius: 0.8vw;
  border-top-right-radius: 0.8vw;
  border-left: #1a222f solid 0.1vw;
  background-color: #73dc6f;
  width: 10%;
}
.leasing1-container img.iconView {
  /* width: 15vw; */
  height: 15vw;
}
.leasing1-container .title-container span {
  font-size: 2.8vw;
}
.leasingInfo-text {
  margin-top: 1vw;
  font-family: "Guaruja Neue Regular";
  line-height: 1.8vw;
  font-size: 1.3vw;
}
span.spanLeasing {
  border: 1px solid;
  border-radius: 0.5vw;
  padding: 0vw 0.5vw;
}
.info-text-container {
  margin-top: 1vw;
  font-family: "Guaruja Neue Regular";
  text-align: justify;
}
.resendCode-container {
  text-align: center;
  font-size: 1.2vw;
  margin-top: 1vw;
  font-family: "Guaruja Neue Regular";
  transition: 0.3s;
}
.resendCode-container:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #4944fc;
  transition: 0.3s;
}
.container-progressBar {
  margin: 1.3vw 0vw;
}
.react-switch-bg,
.react-switch-handle {
  border: 1px solid black !important;
}
.personType-container {
  display: flex;
  align-items: center;
  margin-top: 0.5vw;
  width: 10vw;
  font-family: "Guaruja Neue Regular";
  font-weight: 700;
  font-size: 1.2vw;
  justify-content: space-between;
}
.switch-person-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1vw;
  margin-bottom: 1vw;
}
svg.help-icon {
  margin-left: 0.3vw;
  margin-bottom: 1vw;
  width: 1.5vw;
}
svg.help-icon2 {
  margin-left: 0.3vw;
  width: 1.5vw;
}
.form-twoColumns-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1vw;
  flex-wrap: wrap;
  height: fit-content;
}
.input-twoColumns-container {
  width: 48%;
}
.preliminary-container,
.congrats-container {
  animation-name: slidein;
  animation-duration: 1s;
  z-index: 4000;
  position: absolute;
  left: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 50vw;
  transition: 1s;
  height: -moz-fit-content;
  height: fit-content;
}
.congrats-icon-container img.iconView {
  /* width: 10vw !important; */
  margin-bottom: 1vw;
}
.stepsBar-container {
  align-self: normal;
  padding: 0vw 5vw;
  margin-bottom: 1vw;
}
.congrats-title-container {
  font-size: 5vw;
  font-weight: 800;
}
.preliminary-title-container {
  font-size: 4vw;
  color: #4944fc;
  font-weight: 800;
}
.congrats-subtitle-container {
  font-size: 3vw;
  margin-top: -1vw;
  margin-bottom: 1vw;
  font-weight: 800;
}
.text-info {
  font-family: "Guaruja Neue Regular";
  font-weight: 700;
  text-align: center;
  font-size: 1.2vw;
}

.preliminaryCard-container .amount-container {
  font-size: 2.5vw;
  text-align: center;
  font-weight: 800;
}
.preliminaryCard-container .plain-text {
  font-size: 1.2vw;
  text-align: center;
  font-family: "Guaruja Neue Regular";
}
.amount-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vw;
  font-family: "Guaruja Neue Regular";
  font-size: 1.3vw;
}
.amount-info-container .info-row-amount {
  display: flex;
  justify-content: space-between;
  width: 25vw;
  height: 2.5vw;
  align-items: center;
}
.amount-info-container .info-row-amount .minPayment {
  font-size: 1.4vw;
  color: #4944fc;
  font-weight: 800;
}
.amount-info-container .info-row-amount .maxPayment {
  font-size: 1.4vw;
  color: #73dc6f;
  font-weight: 800;
  background-color: #1a222f;
  border-radius: 0.8vw;
  padding: 0.2vw 0.4vw;
  height: fit-content;
}
.termsPrivacy-container {
  font-family: "Guaruja Neue Regular";
  margin-top: 1vw;
  margin-bottom: 2vw;
}
a.termsPrivacy {
  color: #4944fc;
  text-decoration: underline;
  transition: 0.3s;
}
a.termsPrivacy:hover {
  color: #73dc6f;
  cursor: pointer;
  transition: 0.3s;
}
.session-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 1vw 2vw;
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Guaruja Neue Regular";
  font-weight: 700;
  font-size: 1.3vw;
}
.logoutText {
  font-size: 1.2vw;
  display: flex;
  align-items: center;
  justify-content: end;
  transition: 0.3s;
}
.logoutText svg {
  width: 1.2vw;
  margin-right: 0.3vw;
  margin-bottom: 0.2vw;
}
.logoutText:hover {
  cursor: pointer;
  transition: 0.3s;
  color: #4944fc;
}

/*MENU*/
.menu-container {
  background-color: #1a222f;
  width: 17vw;
  height: 100vh;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2vw;
  padding-bottom: 2vw;
  border-bottom: 1px solid white;
}
.userInfo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.name-container {
  font-size: 1.3vw;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 85%;
  text-transform: capitalize;
}
.itemMenu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: 1vw;
  border-bottom-left-radius: 1vw;
  padding: 0.5vw;
  margin: 0vw 0vw 0vw 1vw;
  transition: 0.2s;
}
.item-general-container:hover .itemMenu-container {
  cursor: pointer;
  background-color: #ededff;
  border-top-left-radius: 1vw;
  border-bottom-left-radius: 1vw;
  transition: 0.2s;
}
.item-general-container:hover .vistaMenu {
  background-color: #ededff;
  transition: 0.2s;
}
.item-general-container.activo #itemMenu-container {
  cursor: pointer;
  background-color: #ededff;
  border-top-left-radius: 1vw;
  border-bottom-left-radius: 1vw;
  transition: 0.2s;
}
.item-general-container.activo .vistaMenu {
  background-color: #ededff;
  transition: 0.2s;
}
.item-general-container.activo .textMenu-container,
.item-general-container.activo .iconMenu {
  color: #1a222f;
  transition: 0.2s;
}

.vistaMenu {
  background-color: #1a222f;
  width: 100%;
  height: 1vw;
  transition: 0.2s;
}
.vistaMenu-up {
  width: 100%;
  height: 1vw;
  background-color: #1a222f;
  border-bottom-right-radius: 1vw;
}
.vistaMenu-down {
  width: 100%;
  height: 1vw;
  background-color: #1a222f;
  border-top-right-radius: 1vw;
}
.item-general-container:hover .textMenu-container,
.item-general-container:hover .iconMenu {
  color: #1a222f;
  transition: 0.2s;
}
.iconMenu-container {
  margin-right: 0.5vw;
}
.iconMenu {
  color: white;
  transition: 0.2s;
}
.textMenu-container {
  color: white;
  font-family: "Guaruja Neue Regular";
  font-size: 1.1vw;
  font-weight: 300;
  transition: 0.5s;
}
.logout-container,
.support-container {
  display: flex;
  justify-content: flex-start;
  padding: 0vw 2vw;
  margin: 1vw 0vw;
  align-items: center;
}
.logout-container svg {
  color: white;
  margin-right: 0.5vw;
  width: 1.2vw;
}
.btn-nuevaSolicitud {
  background-color: white;
  padding: 0.5vw;
  color: #1a222f;
  font-weight: 700;
  width: 100%;
  text-align: center;
  font-family: "Guaruja Neue Regular";
  font-size: 1.1vw;
  border-radius: 0.5vw;
  transition: 0.2s;
}
.btn-nuevaSolicitud:hover {
  cursor: pointer;
  background-color: #9be69f;
  transition: 0.2s;
}
.btn-soporte,
.btn-logout {
  color: white;
  font-weight: 500;
  font-size: 1vw;
  font-family: "Guaruja Neue Regular";
}
.logout-container:hover,
.logout-container:hover svg,
.logout-container:hover .btn-soporte,
.logout-container:hover .btn-logout {
  cursor: pointer;
  color: #9be69f;
  transition: 0.2s;
}
.skinpage-container {
  width: 83%;
  height: 100vh;
}
.dashboard-container {
  padding: 1.5vw;
  background-color: #ededff;
  display: flex;
  flex-direction: row;
  height: 100vh;
  box-sizing: border-box;
}
.dashboard-generalInfo {
  width: 78%;
  padding-right: 2vw;
}
.dashboard-detailInfo {
  width: 22%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.general-subtitle {
  font-size: 1.5vw;
  font-weight: 700;
}
.general-simpleText {
  font-size: 1.2vw;
  font-family: "Guaruja Neue Regular";
}
.infoSubir-container {
  margin: 1.5vw 0vw;
  background-color: #9be69f;
  padding: 1vw;
  width: 85%;
  border-radius: 1vw;
  border: 1px solid;
}
.limiteCredito {
  background-color: #4944fc;
  color: white;
  padding: 0.5vw;
  text-align: center;
  font-weight: 400;
  border-radius: 1.5vw;
  border: 1px solid #4944fc;
}
.detailInfo-container {
  height: 55%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f6f4ea;
  border-radius: 1vw;
  border: 0.2vw solid;
  padding: 1.5vw;
  overflow: auto;
}
.barrasProgreso-container {
  height: 40%;
  overflow: auto;
}
.barrasProgeso-title {
  font-size: 1.2vw;
  font-weight: 700;
}
.nameProgressBar {
  font-size: 1vw;
  font-weight: 400;
}
.carousel-container {
  position: relative;
  margin-bottom: 1.5vw;
  background-color: #9be69f;
  padding: 1vw;
  width: 90%;
  border-radius: 1vw;
  border: 1px solid;
  overflow: hidden;
}
.carousel {
  display: flex;
  height: 3vw;
  align-items: center;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
}
.slide {
  flex: 1;
  margin: 0;
  text-align: center;
  display: none;
  opacity: 0;
  /* visibility: hidden; */
  font-size: 1.2vw;
  font-family: "Guaruja Neue Regular";
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
}
.slide.active {
  display: block;
  opacity: 1;
  /* visibility: visible; */
}
.dots {
  text-align: center;
  margin-top: 0.5vw;
}
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #728ab1;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}
.dot.active {
  background-color: #1a222f;
}
.titleFilters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.general-title {
  font-size: 2vw;
  font-weight: 700;
  color: #1a222f;
}
.filters-container {
  display: flex;
  width: 4vw;
  justify-content: space-between;
  align-items: center;
}
.filterGrid,
.filterList {
  width: 2vw;
  cursor: pointer;
  transition: 0.2s;
}
.filterGrid:hover,
.filterList:hover {
  color: #4944fc;
  transition: 0.2s;
}

/*SECCION CARGA DE DOCUMENTOS*/
.cargaDocumentos-container {
  margin-top: 1.5vw;
  height: 67vh;
  overflow-y: auto;
}
.seccionDocumentos-header {
  display: flex;
  align-items: center;
  transition: 0.2s;
  width: fit-content;
}
.seccionDocumentos-title {
  font-size: 1.5vw;
  font-weight: 700;
  color: #1a222f;
  margin-right: 1vw;
}
.colapseIcon {
  width: 2.5vw;
  height: 2.5vw;
  color: #1a222f;
}
.seccionDocumentos-header:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s;
}
.seccionDocumentos-list {
  margin-top: 1vw;
  margin-right: 1vw;
  display: flex;
}
.seccionDocumentos-item {
  width: 20vw;
  height: 20vw;
  margin-right: 1vw;
  margin-bottom: 1vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5vw;
  box-sizing: border-box;
  transition: 0.2s;
}
.swichAvalRL-container {
  width: 100%;
  height: 3vw;
  display: flex;
  justify-content: center;
  background-color: #2d3b52;
  border-radius: 1vw;
  margin-bottom: 1vw;
  align-items: center;
  flex-direction: row;
}
.switchAvalRL-question {
  font-size: 1vw;
  font-weight: 500;
}
.switch-label {
  margin-left: 0.5vw;
  opacity: 0.8;
}
.btnVolerPendiente {
  font-size: 1vw;
  display: flex;
  justify-content: flex-end;
  padding: 0.5vw 0.5vw;
  border-radius: 0.3vw;
  background-color: #dddcd7;
  color: #1e293b;
  transition: 0.5s;
}
.btnVolerPendiente:hover {
  cursor: pointer;
  background-color: #73dc6f;
  color: #1a222f;
  transition: 0.5s;
}
.seccionDocumentos-item.list {
  width: 100%;
  height: 5vw;
  flex-direction: row;
  background-color: #e8e8fa;
  border-style: solid !important;
  border-width: 0.2vw !important;
}
.seccionDocumentos-item.list.modal {
  width: 100%;
  height: fit-content;
  max-height: 25vw;
  flex-direction: column;
  background-color: #e8e8fa;
  border-style: solid !important;
  border-width: 0.2vw !important;
  overflow: auto;
}
.seccionDocumentos-item.list.modal:hover {
  cursor: initial;
  opacity: 1;
}
.seccionDocumentos-item.list.modal .doc-view {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f4ea;
  padding: 0.3vw;
  border-radius: 1vw;
  margin: 0.2vw 0vw;
  transition: 0.2s;
}
.seccionDocumentos-item.list.modal .doc-view:hover {
  cursor: initial;
  background-color: #dddcd7;
  transition: 0.2s;
}
.seccionDocumentos-item:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s;
}
.seccionDocumentos-item .iconItem {
  width: 5vw;
  height: 5vw;
  margin-top: 0.3vw;
}
.seccionDocumentos-item.list .iconItem {
  width: 3vw;
  height: 3vw;
  margin-top: 0vw;
}
.seccionDocumentos-item.list .docList-title {
  font-size: 1vw !important;
  font-style: italic !important;
}
.seccionDocumentos-item .item-title {
  color: #4944fc;
  text-align: center;
  font-weight: 700;
  font-size: 1vw;
  margin: 0.2vw 0vw;
  height: 2.8vw;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.seccionDocumentos-item .item-icon-grid.list {
  width: 15%;
  display: flex;
  justify-content: center;
}
.seccionDocumentos-item .item-title.list {
  width: 50%;
  height: inherit;
}
.seccionDocumentos-item .item-action.list {
  width: 40%;
  text-align: center;
}
.seccionDocumentos-item .item-docList {
  width: 100%;
  padding: 0.4vw;
  box-sizing: border-box;
}
.seccionDocumentos-item .docList-title {
  font-size: 1vw;
  font-family: "Guaruja Neue Regular";
  font-weight: 200;
  color: #2d3b52;
}
.seccionDocumentos-item .doc-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f4ea;
  padding: 0.3vw;
  border-radius: 1vw;
  margin: 0.2vw 0vw;
}
.seccionDocumentos-item .doc-view:hover {
  cursor: pointer;
}
.doc-view .doc-view-title {
  font-size: 0.9vw;
  font-family: "Guaruja Neue Regular";
  color: #415575;
}
.item-action {
  font-size: 1.1vw;
  font-family: "Guaruja Neue Regular";
  color: #415575;
  text-decoration: underline;
  transition: 0.2s;
}
.item-action:hover {
  cursor: pointer;
  color: #4944fc;
  transition: 0.2s;
}
.seccionDocumentos-container {
  margin-bottom: 1vw;
}
.collapse-content {
  display: flex;
  overflow-y: scroll;
}

/*MODALES*/
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body-modal {
  width: 60vw;
}
.bodyGModal {
  background-color: #1a222f;
  color: #f6f4ea;
  height: 37vw;
}
.subtitleModal-container {
  font-family: "Guaruja Neue Regular";
  font-size: 1vw;
  font-weight: 400;
}
.jWkLDY .file-types {
  display: none;
}
label.sc-aXZVg.guZdik {
  width: 100%;
  height: 25vw;
  border: 0.3vw dashed lightgray;
  padding: 2vw;
  flex-direction: column;
  justify-content: center;
}
.jWkLDY > span {
  font-weight: 500;
  text-align: center;
  opacity: 0.7;
}
.kFhUBM span {
  text-decoration: none !important;
}
label.sc-aXZVg.guZdik svg {
  width: 6vw;
  height: 6vw;
  opacity: 0.7;
}
label.sc-aXZVg.guZdik svg path {
}
.jWkLDY {
  text-align: center;
  flex-grow: initial !important;
}
.btnsFooter {
  background-color: #1a222f;
}
.btn-uploadFiles {
  cursor: pointer;
  background-color: white;
  padding: 0.5vw;
  color: #1a222f;
  font-weight: 700;
  width: 100%;
  text-align: center;
  font-family: "Guaruja Neue Regular";
  font-size: 1.1vw;
  border-radius: 0.5vw;
  transition: 0.2s;
  margin-top: 2vw;
}
.container-withFiles {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.docsUploaded-container {
  width: 60%;
  padding-right: 2vw;
}
li.selectedFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3vw 0.5vw;
  border-radius: 0.4vw;
  margin-bottom: 0.2vw;
}
li.selectedFile:hover {
  background-color: #9be69f2c;
  transition: 0.2s;
}
span.docName-span {
  width: 26vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nombreCarpeta-modal {
  font-size: 1.2vw;
  text-transform: capitalize;
  font-weight: 600;
  font-family: "Guaruja Neue Regular";
  background-color: #9be69f;
  border-radius: 0.5vw;
  padding: 0.3vw 0.5vw;
  color: #1a222f;
  margin-bottom: 1vw;
}

/*RESPONSIVE*/
@media screen and (max-width: 768px) {
  .welcome-container {
    width: 85% !important;
  }
  .info-container {
    padding: 1.5rem;
  }
  img.iconView {
    width: 60%;
  }
  img.btnNext {
    width: 2.6rem;
    margin-left: -30%;
  }
  .title-container span {
    font-size: 2rem;
  }
  .text-container {
    text-align: justify;
  }
  .welcome-btn-container {
    margin-top: 2rem;
    width: 9rem;
  }
  .btn-container {
    border-radius: 0.5rem;
    height: 2rem;
  }
  .textBtn-container {
    font-size: 0.9rem;
  }
  .leasing-container {
    margin-top: 1rem;
  }
  .leasingText-btn {
    font-size: 0.9rem;
  }
  svg.backButton {
    width: 2rem;
    height: 2rem;
  }
  .input-container {
    margin-bottom: 1rem;
  }
  .icon-container svg {
    width: 18px;
  }
  .input-container .input-item {
    font-size: 0.8rem;
    padding: 0rem 0.5rem;
  }
  .btn-container2 {
    height: 2.5rem;
    border-radius: 0.7rem;
  }
  .textBtn-container2 {
    font-size: 0.7rem;
  }
  .forgotPass-container {
    margin-bottom: 1rem;
  }
  .forgotPass-text {
    font-size: 0.8rem;
  }
  .otherOptions-container {
    margin-top: 1rem;
  }
  .divisor-container {
    font-size: 1rem;
  }
  .line {
    height: 0.1rem;
    width: 7rem;
  }
  .input-label {
    padding: 0.2rem 0.2rem;
  }
  .container-progressBar {
    margin: 1.3rem 0rem;
  }
  .progressBar-container {
    padding: 0.7rem;
    border-radius: 0.5rem;
  }
  .line-progressBar {
    height: 0.2rem;
  }
  .line-progressBar-inner {
    height: 0.2rem;
  }
  .dot-progressBar {
    width: 1rem;
    height: 1rem;
  }
  .porcent-progressBar {
    font-size: 0.8rem;
  }
  .title-container-register span {
    font-size: 2rem;
    letter-spacing: -0.1rem;
    line-height: 1.5rem;
  }
  .personType-container {
    margin-top: 0.5rem;
    width: 7rem;
    font-size: 1rem;
  }
  svg.help-icon {
    margin-left: 0.2rem;
    margin-bottom: 0.5rem;
    width: 1rem;
  }
  div#my-tooltip {
    width: fit-content !important;
    font-size: 0.7rem !important;
  }
  .form-twoColumns-container {
    margin-top: 1rem;
    flex-wrap: nowrap;
    height: 18rem !important;
    overflow: scroll;
    flex-direction: column;
  }
  .input-twoColumns-container {
    width: 100%;
  }
  .selloAnimado {
    display: none;
  }
  .leasingCards-container {
    flex-direction: column;
    overflow: scroll;
  }
  .leasing1-container {
    width: 80%;
    height: fit-content;
    border-radius: 0.8rem;
    margin-bottom: 1.5rem;
  }
  .leasing1-container .title-container span {
    font-size: 1.8rem;
  }
  .leasingInfo-text {
    margin-top: 1rem;
    font-size: 1rem;
    text-align: justify;
    line-height: 1.2rem;
  }
  .preliminary-container,
  .congrats-container {
    width: 80%;
  }
  .congrats-icon-container img.iconView {
    width: 100% !important;
  }
  .stepsBar-container {
    margin-bottom: 1.5rem;
  }
  .congrats-title-container {
    font-size: 3rem;
  }
  .congrats-subtitle-container {
    font-size: 1.5rem;
  }
  .text-info {
    font-size: 1rem;
  }
  .preliminary-title-container {
    font-size: 3rem;
    line-height: 3rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  .preliminaryCard-container {
    width: 100%;
    margin-top: 2rem;
  }
  .preliminaryCard-container .header-container {
    font-size: 1.5rem;
  }
  .preliminaryCard-container .amount-container {
    font-size: 2rem;
  }
  .preliminaryCard-container .plain-text {
    font-size: 1rem;
  }
  .amount-info-container {
    font-size: 0.8rem;
    margin-top: 1rem;
  }
  .amount-info-container .info-row-amount {
    width: 85%;
    height: fit-content;
  }
  .amount-info-container .info-row-amount .maxPayment,
  .amount-info-container .info-row-amount .minPayment {
    font-size: 1rem;
  }
}
.top[data-v-5449dad6] {
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}
.base[data-v-5449dad6] {
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}
.shadow[data-v-5449dad6] {
  fill: currentColor;
  color: transparent;
  opacity: 0.2;
}
.letters[data-v-5449dad6] {
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.base[data-v-5449dad6],
.top[data-v-5449dad6] {
  stroke: #1e293b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: none;
}
.steps-container {
  width: 100%;
}
.titleModalMoffin {
  display: flex;
  font-size: 2.2vw;
  font-family: "filson_proregular";
  align-items: center;
  justify-content: center;
}
.subtitleModalMoffin {
  display: flex;
  font-size: 1vw;
  font-family: "Guaruja Neue Regular";
  align-items: center;
  justify-content: center;
}
.subtitle-datos-moffin {
  font-size: 2vw;
  font-family: "filson_proregular";
}
.autorizacion-moffin-parrafo {
  font-size: 1vw;
  font-family: "filson_proregular";
  text-align: justify;
  margin-bottom: 1vw;
}
.detailTitle {
  font-size: 1.1vw;
  line-height: 1vw;
  margin-bottom: 0.5vw;
  text-align: center;
  font-family: "filson_proregular";
  font-weight: 700;
}
.detailTitle.revision {
  color: #4944fc;
}
.detailTitle.rechazado {
  color: #415575;
}
.detailTitle.aprobado {
  color: #73dc6f;
}
.detailText {
  font-size: 0.9vw;
  font-family: "Guaruja Neue Regular";
  font-weight: 300;
  line-height: 1.2vw;
  text-align: justify;
  margin: 0.5vw 0vw 0.8vw 0vw;
}
.detailMoreInfo {
  border: solid 0.1vw #2d3b52;
  border-radius: 1vw;
  padding: 0.8vw;
  font-size: 0.8vw;
  line-height: 1vw;
  text-align: justify;
  font-family: "Guaruja Neue Regular";
  color: #2d3b52;
}
.btn-addTable {
  padding: 0.5vw 1vw;
  background-color: #f6f4ea;
  color: #1a222f;
  border-radius: 1vw;
  border: 0.1vw #1a222f solid;
  font-family: "filson_proregular";
  font-size: 1vw;
  font-weight: 700;
  transition: 0.3s;
}
.btn-addTable:hover {
  transition: 0.3s;
  cursor: pointer;
  background-color: #73dc6f;
}
.requestsList-container {
  margin-top: 1vw;
  margin-bottom: 1vw;
}
/*CUSTOM TABLE*/
.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.headers-container {
  display: flex;
  color: #546e99;
  font-family: "filson_proregular";
  font-size: 1vw;
  font-weight: 400;
}
.cell.icon-cell,
.item-header.icon-header {
  width: 5%;
  display: flex;
  justify-content: center;
}
.rows-container {
  width: 100%;
  max-height: 13vw;
  overflow: auto;
  /* background-color: #a09fd5; */
  border-radius: 1vw;
  /* padding: 0.3vw;*/
}
.rows-container .item-row {
  width: 100%;
  display: flex;
  background-color: #c8c7fe;
  border-radius: 0.5vw;
  margin-bottom: 0.4vw;
  height: 3vw;
  align-items: center;
}
.rows-container .item-row .cell {
  font-family: "filson_proregular";
  font-size: 1.1vw;
  font-weight: 500;
}
.item-header.actions-header {
  text-align: center;
  width: 10%;
}
.cell.actions-cell {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 10%;
}
.cell.actions-cell button:hover {
  color: #4944fc;
  transition: 0.3s;
}
.requestMessageEmpty {
  font-style: italic;
  justify-content: center;
  font-size: 1vw;
  font-family: "filson_proregular";
  background-color: #8e9aac73;
  height: 2vw;
  display: flex;
  align-items: center;
  border-radius: 1vw;
  opacity: 0.5;
}
/*MODAL DETALLE*/
.detalle-container {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  width: 100%;
}
.detalleIzq {
  width: 48%;
}
.detalleDer {
  width: 48%;
  text-align: center;
}
.detalle-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1vw;
}
.detalle-name {
  font-size: 1.2vw;
  width: fit-content;
  font-weight: 600;
  font-family: "filson_proregular";
  background-color: #9be69f;
  border-radius: 0.5vw;
  padding: 0.3vw 0.5vw;
  color: #1a222f;
  text-transform: capitalize;
}
.detalle-subtitle {
  font-size: 1vw;
  color: #9be69f;
  font-weight: 400;
  font-family: "filson_proregular";
}
.detalle-name2 {
  font-size: 1.2vw;
  font-weight: 600;
  font-family: "filson_proregular";
  background-color: #9be69f;
  border-radius: 1vw;
  padding: 0.3vw 0.5vw;
  color: #1a222f;
}

.closeTagModal {
  position: absolute;
  right: 3vw;
  top: 3.5vw;
  width: 2vw;
  height: 2vw;
  transition: 0.3s;
}
.closeTagModal:hover {
  cursor: pointer;
  color: #9be69f;
  transition: 0.3s;
}

.input-item-custom {
  font-family: "Guaruja Neue Regular" !important;
  font-size: 1rem !important;
  height: 95% !important;
  width: 100% !important;
  padding: 0vw 1vw !important;
  border: none !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
input {
  outline: none !important;
}
.shadow-custom-1 {
  box-shadow: 0 1px 24px -3px #919191bf;
  -webkit-box-shadow: 0 1px 24px -3px #919191bf;
  -moz-box-shadow: 0 1px 24px -3px #919191bf;
}
